.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.App {
  text-align: center;
  margin-top: 50px;

}

form {
  margin-bottom: 20px;
}



.circle-container {
  position: relative;
  width: 30vh;
  height: 250px;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-item {
  position: absolute;
  width: 40px;
  height: 80px;
  /* background-color: #5edb34; */
  background-image: url('./images/achtergrondkaart2.jpg');
  background-size: 10vw;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: move;
  touch-action: none;
  transform-origin: 50% 100%;

  transform: rotate(calc(360deg / 52 * (var(--card-index) - 1))) translate(15vh, -50%);
}

.image-overlay,
.image-overlay1,
.image-overlaybutton {
  position: fixed;
  top: 20vh;
  left: 15vw;
  min-height: 400px;
  height: 500px;
  /* Semi-transparante zwarte achtergrond */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-overlay img,
.image-overlay1 img {
  max-width: 100%;
  /* Aanpassen aan je behoeften */
  max-height: 80%;
  /* Aanpassen aan je behoeften */
}

.image-overlay {
  z-index: 1;

}

.image-overlay1 {
  z-index: 10;
  margin-left: -10vw;
}

.image-overlaybutton {
  width: 72vw;
  z-index: 20;
  display: flex;
  justify-content: center;
  margin-top: 30vh;
}

.buttonimagedelete {
  height: 10vh;
  width: 50vw;
  font-size: 190%;
}



/* --------------------------cards ----------------- */

.circle-item:nth-child(1) {
  --card-index: 1;
}

.circle-item:nth-child(2) {
  --card-index: 2;
}

.circle-item:nth-child(3) {
  --card-index: 3;
}

.circle-item:nth-child(4) {
  --card-index: 4;
}

.circle-item:nth-child(5) {
  --card-index: 5;
}

.circle-item:nth-child(6) {
  --card-index: 6;
}

.circle-item:nth-child(7) {
  --card-index: 7;
}

.circle-item:nth-child(8) {
  --card-index: 8;
}

.circle-item:nth-child(9) {
  --card-index: 9;
}

.circle-item:nth-child(10) {
  --card-index: 10;
}

.circle-item:nth-child(11) {
  --card-index: 11;
}

.circle-item:nth-child(12) {
  --card-index: 12;
}

.circle-item:nth-child(13) {
  --card-index: 13;
}

.circle-item:nth-child(14) {
  --card-index: 14;
}

.circle-item:nth-child(15) {
  --card-index: 15;
}

.circle-item:nth-child(16) {
  --card-index: 16;
}

.circle-item:nth-child(17) {
  --card-index: 17;
}

.circle-item:nth-child(18) {
  --card-index: 18;
}

.circle-item:nth-child(19) {
  --card-index: 19;
}

.circle-item:nth-child(20) {
  --card-index: 20;
}

.circle-item:nth-child(21) {
  --card-index: 21;
}

.circle-item:nth-child(22) {
  --card-index: 22;
}

.circle-item:nth-child(23) {
  --card-index: 23;
}

.circle-item:nth-child(24) {
  --card-index: 24;
}

.circle-item:nth-child(25) {
  --card-index: 25;
}

.circle-item:nth-child(26) {
  --card-index: 26;
}

.circle-item:nth-child(27) {
  --card-index: 27;
}

.circle-item:nth-child(28) {
  --card-index: 28;
}

.circle-item:nth-child(29) {
  --card-index: 29;
}

.circle-item:nth-child(30) {
  --card-index: 30;
}

.circle-item:nth-child(31) {
  --card-index: 31;
}

.circle-item:nth-child(32) {
  --card-index: 32;
}

.circle-item:nth-child(33) {
  --card-index: 33;
}

.circle-item:nth-child(34) {
  --card-index: 34;
}

.circle-item:nth-child(35) {
  --card-index: 35;
}

.circle-item:nth-child(36) {
  --card-index: 36;
}

.circle-item:nth-child(37) {
  --card-index: 37;
}

.circle-item:nth-child(38) {
  --card-index: 38;
}

.circle-item:nth-child(39) {
  --card-index: 39;
}

.circle-item:nth-child(40) {
  --card-index: 40;
}

.circle-item:nth-child(41) {
  --card-index: 41;
}

.circle-item:nth-child(42) {
  --card-index: 42;
}

.circle-item:nth-child(43) {
  --card-index: 43;
}

.circle-item:nth-child(44) {
  --card-index: 44;
}

.circle-item:nth-child(45) {
  --card-index: 45;
}

.circle-item:nth-child(46) {
  --card-index: 46;
}

.circle-item:nth-child(47) {
  --card-index: 47;
}

.circle-item:nth-child(48) {
  --card-index: 48;
}

.circle-item:nth-child(49) {
  --card-index: 49;
}

.circle-item:nth-child(50) {
  --card-index: 50;
}

.circle-item:nth-child(51) {
  --card-index: 51;
}

.circle-item:nth-child(52) {
  --card-index: 52;
}

.dropzone {
  position: relative;

}



#outer-dropzone {
  margin-top: 180%;
  position: absolute;
  width: 100%;
  border: 2px dashed #000;
  /* Stel de gewenste stijl van de rand in */
  pointer-events: none;
  /* Zodat de dropzone de drag-and-drop-interacties niet onderbreekt */
}

#outer-dropzone {
  height: 140px;
  top: 0;
}


.drop-target {
  /* kleur dropzone */
  background-color: rgb(206, 255, 10);
  border-color: #fff;
  border-style: solid;
}


.drag-drop.can-drop {
  /* als kaart is neergezet */
  color: #000;
  /* background-color: rgb(255, 0, 255); */
  background-color: transparent;
  background-image: none;
}

.information {
  height: 5vh;
  display: flex;
  margin-left: 88vw;
  margin-top: -5vh;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(176, 176, 176);
  /* Halfdoorzichtige achtergrond */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.rulepopup {
  color: black;
  display: flex;
  max-height: 80vh;
  flex-direction: column;
  font-size: 3vw;
  overflow: auto;
}